<template>
  <div class="list-filter">
    <div class="bg-white">
      <div class="col-md-12">
        <div class="row">
          <div class="col-12 col-md-3">
            <b-form-group
              id="input-group-phone"
              label="Телефон"
              label-for="input-phone"
            >
              <b-form-input
                id="input-phone"
                v-model.trim="f.Phone"
                placeholder="Телефон"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-12 col-md-3">
            <b-form-group
              id="input-group-surname"
              label="Фамилия"
              label-for="input-surname"
            >
              <b-form-input
                id="input-surname"
                v-model.trim="f.Surname"
                placeholder="Фамилия"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-12 col-md-3">
            <b-form-group
              id="input-group-name"
              label="Имя"
              label-for="input-name"
            >
              <b-form-input
                id="input-name"
                v-model.trim="f.Name"
                placeholder="Имя"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-12 col-md-3">
            <b-form-group
              id="input-group-last-name"
              label="Отчество"
              label-for="input-last-name"
            >
              <b-form-input
                id="input-last-name"
                v-model.trim="f.Patronymic"
                placeholder="Отчество"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_USERS_FILTER_WHO, GET_USERS_FILTER_WHOM } from '@/core/services/store/users.module';
export default {
  props: {
    type: String,
  },
  data() {
    return {
      f: {
        Phone: this.$route.query?.Phone,
        Surname: this.$route.query?.Surname,
        Name: this.$route.query?.Name,
        Patronymic: this.$route.query?.Patronymic,
      },
    };
  },
  mounted() {
    this.findUsers();
  },
  watch: {
    f: {
      handler(v) {
        this.applyFilters(v);
      },
      deep: true,
    },
  },
  methods: {
    async findUsers() {
      if(this.type == 'who') {
        await this.$store.dispatch(GET_USERS_FILTER_WHO, this.$route.query);
      }
      else if(this.type == 'whom') {
        await this.$store.dispatch(GET_USERS_FILTER_WHOM, this.$route.query);
      }
    },
    applyFilters(filters = {}) {
      const query = { ...this.$route.query };

      Object.keys(filters).forEach((item) => {
        if (
            filters[item] === undefined ||
            filters[item] === false ||
            filters[item] === ''
        ) {
          delete query[item];
        } else {
          query[item] = filters[item];
        }
      });

      if (Object.keys(query).length === 0) {
        if (Object.keys(this.$route.query).length > 0) {
          this.$router.push({ name: this.$route.name, query });
          this.findUsers();
          return;
        }
      } else {
        this.$router.push({ name: this.$route.name, query });
        this.findUsers();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list-filter {
  margin-bottom: 25px;

  .row-switches {
    @media (max-width: 658px) {
      flex-direction: column;
    }
    .custom-switch:not(:first-child) {
      @media (min-width: 659px) {
        margin-left: 25px;
      }
    }
  }
}
</style>
