<template>
  <div>
    <div class="d-flex justify-content-between align-items-center flex-wrap p-4 bg-white custom-header">
      <div class="d-flex justify-content-start left-content">
        <h1 style="margin-bottom: 0px;">Кто уже подменяется</h1>
      </div>
      <div class="d-flex justify-content-end"></div>
    </div>
    <div class="auto-dealer-table">
      <v-data-table
          :headers="headers"
          :items="filterItems"
          disable-pagination
          item-key="name"
          class="elevation-1 line"
          hide-default-footer
          :header-props="{ 'sort-icon': 'mdi-sort-variant' }"
      >
        <template #item.login="{ item }">
          <div class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <span class="svg-icon svg-icon-md svg-icon-primary" @click="userLogOut(item.id)">
              <inline-svg class="burger-icon" src="/media/svg/icons/Navigation/Close.svg"></inline-svg>
            </span>
          </div>
        </template>
        <template #no-data>
          <div>Не найдены записи</div>
        </template>

        <template #no-results>
          <div>Не найдены записи</div>
        </template>
      </v-data-table>
    </div>

    <div class="mt-10 mb-4" v-if="!showFilters">
      <div @click="openFilter" class="btn btn-success">Добавить подмену</div>
    </div>

    <template v-if="showFilters">

      <template v-if="showFilterWho">
        <div class="d-flex justify-content-between align-items-center flex-wrap mt-4 p-4 bg-white custom-header">
          <div class="d-flex justify-content-start left-content">
            <h1>Выберите, кого подменить</h1>
          </div>
        </div>

        <users-list-filter :type="'who'" :key="111" />

        <div class="auto-dealer-table">
        <v-data-table
          :headers="headersFilter"
          :items="filterItemsWho"
          disable-pagination
          item-key="name"
          class="elevation-1 row-links"
          hide-default-footer
          :header-props="{ 'sort-icon': 'mdi-sort-variant' }"
        >
          <template #item.login="{ item }">
            <div class="label label-lg label-inline label-light-success" @click="selectWho(item.ID, item.Surname, item.Name, item.Patronymic)">Выбрать</div>
          </template>
          <template #no-data>
            <div>Не найдены записи</div>
          </template>

          <template #no-results>
            <div>Не найдены записи</div>
          </template>
        </v-data-table>
      </div>

      </template>
      <template v-else>
        <div class="mt-4"><span @click="toStepWho" title="Изменить" class="linkLike">Кого подменять: {{userName(idWho.surname, idWho.name, idWho.patronymic, idWho.id)}}</span></div>

        <template v-if="showFilterWhom">
          <div class="d-flex justify-content-between align-items-center flex-wrap mt-4 p-4 bg-white custom-header">
            <div class="d-flex justify-content-start left-content">
              <h1>Выберите, на кого подменить</h1>
            </div>
          </div>

          <users-list-filter :type="'whom'" :key="222" />

          <div class="auto-dealer-table">
            <v-data-table
              :headers="headersFilter"
              :items="filterItemsWhom"
              disable-pagination
              item-key="name"
              class="elevation-1 row-links"
              hide-default-footer
              :header-props="{ 'sort-icon': 'mdi-sort-variant' }"
            >
              <template #item.login="{ item }">
                <div class="label label-lg label-inline label-light-success" @click="selectWhom(item.ID, item.Surname, item.Name, item.Patronymic)">Выбрать</div>
              </template>
              <template #no-data>
                <div>Не найдены записи</div>
              </template>

              <template #no-results>
                <div>Не найдены записи</div>
              </template>
            </v-data-table>
          </div>

        </template>
        <template v-else>
          <div><span @click="toStepWhom" title="Изменить" class="linkLike">На кого подменять: {{userName(idWhom.surname, idWhom.name, idWhom.patronymic, idWhom.id)}}</span></div>
          <br>
          <div @click="userLogIn" class="btn btn-success">Отправить</div>
        </template>


      </template>

    </template>

  </div>
</template>

<script>
import {
  SET_BREADCRUMB,
} from '@/core/services/store/breadcrumbs.module';
import { mapState } from 'vuex';
import UsersListFilter from '../../../content/filters/UsersFilter.vue';
import { GET_USERS_CHANGED, USERS_LOG_IN, USERS_LOG_OUT } from '@/core/services/store/users.module';

export default {
  name: 'Users',
  components: { UsersListFilter },
  beforeMount() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Пользователи' },
    ]);
  },
  async mounted() {
    await this.updateList();
  },
  data() {
    return {
      headers: [
        {text: 'Кто', value: 'who', sortable: true},
        {text: 'Под кем', value: 'whom', sortable: true},
        {text: '', value: 'login', sortable: false},
      ],
      showFilters: false,
      showFilterWho: true,
      showFilterWhom: true,
      headersFilter: [
        { text: 'Телефон', value: 'Phone', sortable: true },
        { text: 'Фамилия', value: 'Surname', sortable: true },
        { text: 'Имя', value: 'Name', sortable: true },
        { text: 'Отчество', value: 'Patronymic', sortable: true },
        { text: '', value: 'login', sortable: false },
      ],
      idWho: false,
      idWhom: false,
    }
  },
  computed: {
    filterItems() {
      let self = this;
      let result = this.items.map(function(i) {
        return {
          'id': i.User.id,
          'who': self.userName(i.User.surname, i.User.name, i.User.patronymic, i.User.id),
          'whom': self.userName(i.ImpersonatedUser.surname, i.ImpersonatedUser.name, i.ImpersonatedUser.patronymic, i.ImpersonatedUser.id),
        };
      });
      return result;
    },
    filterItemsWho() {
      let res = [];
      if(this.itemsFilterWho)
        res = this.itemsFilterWho;
      return res;
    },
    filterItemsWhom() {
      let res = [];
      if(this.itemsFilterWhom)
        res = this.itemsFilterWhom;
      return res;
    },
    ...mapState({
      items: (state) => state.users.usersChangedItems,
      itemsFilterWho: (state) => state.users.usersFilterWho,
      itemsFilterWhom: (state) => state.users.usersFilterWhom,
    }),
  },
  methods: {
    async userLogIn() {
      let who=this.idWho.id;
      let whom=this.idWhom.id;

      if(who && whom) {
        let res = await this.$store.dispatch(USERS_LOG_IN, { UserID: who, ImpersonatedUserID: whom });
        if(res.data?.data?.Success) {
          await this.updateList();
          this.resetAll();
        }
        else
          alert('Подмена не создана');
      }

    },
    async userLogOut(id) {
      if(id) {
        let res = await this.$store.dispatch(USERS_LOG_OUT, { UserID: id });
        if(res.data?.Success)
          await this.updateList();
        else
          alert('Запись не удалена');
      }
    },
    async updateList() {
      await this.$store.dispatch(GET_USERS_CHANGED);
    },

    userName(s,n,p) {
      return s+' '+n+' '+p;
    },

    //фильтры для добавления новой записи
    openFilter() {
      this.clearGet();
      this.showFilters = true;
    },
    selectWho(id, s, n, p) {
      if(id>0) {
        this.idWho = {
          id: id,
          surname: s,
          name: n,
          patronymic: p,
        };
        this.showFilterWho = false;
        this.clearGet();
      }
      else
        alert('Не выбран пользователь');
    },
    selectWhom(id, s, n, p) {
      if(id>0) {
        this.idWhom = {
          id: id,
          surname: s,
          name: n,
          patronymic: p,
        };
        this.showFilterWhom = false;
        this.clearGet();
      }
      else
        alert('Не выбран пользователь');
    },
    clearGet() {//очистить get-строку
      this.$router.replace({'query': null}).catch(() => {});
    },
    toStepWho() {
      this.resetWho();
    },
    toStepWhom() {
      this.resetWhom();
    },
    resetWho() {
      this.idWho = false;
      this.showFilterWho = true;
    },
    resetWhom() {
      this.idWhom = false;
      this.showFilterWhom = true;
    },
    resetAll() {
      this.showFilters = false;
      this.resetWho();
      this.resetWhom();
    },
  },
};
</script>

<style lang="scss">
.text-start {
  cursor: default;
}
</style>
